<template>
  <div>
    <button
      v-if="$route.params.uuid"
      class="btn btn-default btn-sm mt-4 px-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div
      class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
      id="uploadImage"
    >
      <div class="card-body px-lg-5 py-lg-3">
        <div class="row">
          <div class="col-md-12">
            <h3>Upload Image</h3>
            <hr class="my-0 mb-3" />
            <div class="row">
              <div class="col-md-6">
                <drop-zone
                  :options="optionsImage"
                  @change="get_advert"
                  v-if="!data.iconImage"
                ></drop-zone>
                <span v-else>Remove the Existing image to upload new</span>
              </div>
              <div class="col-md-6">
                <div class="card p-1">
                  <h5>Existing Image</h5>
                  <hr class="my-0 mb-2" />
                  <img
                    v-if="data.iconImage"
                    :src="backend_url + data.iconImage"
                    alt=""
                    class="w-100"
                    style="height: 270px"
                  />
                  <span v-else class="text-muted text-center"
                    >No Existing Logo</span
                  >
                  <hr class="my-1 mb-2" />
                  <button
                    class="btn btn-danger"
                    v-if="data.iconImage"
                    @click="removeIconImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div class="mt-2 float-right">
      <router-link
        :to="{
          name: 'CarouselDetail',
          params: { uuid: $route.params.uuid },
        }"
      >
        <button
          type="button"
          class="btn btn-block btn-lg btn-default my-4 btn-md px-4"
        >
          <em class="ni ni-check-bold"></em>
          Done
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Dropzoned from "../../components/DropzoneComp";
import { alert } from "../../utils/alertUtils";
export default {
  components: {
    "drop-zone": Dropzoned,
  },
  data() {
    return {
      slides:[
                {
          headTitle: "Training on Public Procurement and E-procurement.",
          content:
            "New Faces New Voices Rwanda in partnership with Rwanda Public Procurement Authority has organized a two days training on Tender Laws, usage of e-procurement platform(Umucyo) .. .. https://umucyo.gov.rw/",
          buttonText: "Register Here",
          styleObject: {
            backgroundColor: "#e6e7ed",
            htmlStyle: {
              color: "#192B3E",
            },
            btn: {
              backgroundColor: "#192B3E",
              color: "#e6e7ed",
            },
          },
          image:
            "https://firebasestorage.googleapis.com/v0/b/kene-6c09d.appspot.com/o/home2.jpg?alt=media&token=ac8b74c6-1f73-40ad-8ca6-06e6d37ed16f",
          link: "mailto:Secretariat@nfnv.rw",
        },
      ],
      backend_url: process.env.VUE_APP_BACKEND_URL,
      data: {},
      optionsImage: {
        id: "carouselImage",
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/posts/${this.$route.params.uuid}/upload/image/`,
        maxFilesize: 2,
        maxFiles: 1,
        on_sending: function (file, xhr, form_data) {
          form_data.append("file", file);
        },
        // on_remove: function (file) {
        //   if (file.status === "success") {
        //     axios
        //       .delete(
        //         `${process.env.VUE_APP_BACKEND_URL}/api/entities/${file.response.uuid}/LogoImage/removal`,
        //         {
        //           headers: {
        //             Authorization: `Bearer ${this.options.the.$store.getters.getToken}`,
        //           },
        //         }
        //       )
        //       .then((res) => {
        //         console.log(res);
        //         this.options.the.$emit("change");
        //       })
        //       .catch((err) => {
        //         console.error(err);
        //       });
        //   }
        // },
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
          
        },
      },
    };
  },
  methods: {
    get_advert: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/posts/${this.$route.params.uuid}`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.data = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeIconImage: function () {
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/api/posts/${this.$route.params.uuid}/image/Removal/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Image removed");
          this.get_advert();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get_advert();
  },
};
</script>
<style lang="css" scoped>
</style>